// START TEST PLOCTOPLUS-2313: Center Content In CAMPAIGN Hero Upsell Bucket
import { AbTest } from 'abTests/lib/AbTest';
import Breakpoints from 'helpers/breakpoints';

const AB_TEST_MODULE = 'CenterContentInCampaignHeroUpsellBucket';
const HAPPY_KEY = 'happy';
const VARIANT_GENERAL = 'centered_content_general_show_wall';
const VARIANT_SPECIFIC = 'centered_content_ip_specific_show_wall';
const HERO_ASSETS = {
    // fallback for control (filled in by CenterContentInCampaignHeroesHandler constructor)
    [HAPPY_KEY]: {
        mobile: '',
        desktop: '',
    },

    // variant 1 (assets from prod default upsell)
    [VARIANT_GENERAL]: {
        mobile: 'https://wwwimage-us.pplusstatic.com/thumbnails/photos/w828-q80/cbs_page_attribute/26/53/3/image_eadb18f9-a912-4f23-85c2-90adf288c3bb.jpg',
        desktop:
            'https://wwwimage-us.pplusstatic.com/thumbnails/photos/w1920-q80/cbs_page_attribute/26/53/2/image_d91e649f-35ce-43ce-8726-6f4eab8502ec.jpg',
    },

    // variant 2
    [VARIANT_SPECIFIC]: {
        // placeholder (filled in by CenterContentInCampaignHeroesHandler constructor)
        mobile: '',
        desktop: '',

        // campaign variant assets provided by Design
        nfl: {
            mobile: 'https://wwwimage-us.pplusstatic.com/base/files/asset/10/00/99/48/30da048a49b7dfda_nfl_dw.jpg',
            desktop:
                'https://wwwimage-us.pplusstatic.com/base/files/asset/10/00/99/48/2e253b20a6ff4f17_nfl_desktop.jpeg',
        },
        sports: {
            mobile: 'https://wwwimage-us.pplusstatic.com/base/files/asset/10/00/99/48/505231c6daf9ae30_sports_tablet.jpeg',
            desktop:
                'https://wwwimage-us.pplusstatic.com/base/files/asset/10/00/99/48/bcc4ef9c1b90238e_sports_desktop.jpeg',
        },
    },
};
const VIDEO_SELECTORS = ['#js-video-portrait', '#js-video-tablet', '#js-video-landscape'];

const GENERIC_FUNCTION = function () {};

export class CenterContentInCampaignHeroUpsellBucket extends AbTest {
    onRun() {
        new CenterContentInCampaignHeroesHandler(this);
    }
}

class CenterContentInCampaignHeroesHandler {
    constructor(abTest) {
        this.abTest = abTest;
        this.breakpoints = new Breakpoints();
        this.heroAssets = {
            ...HERO_ASSETS,
            [VARIANT_SPECIFIC]: {
                ...HERO_ASSETS[VARIANT_SPECIFIC][this.getCbscidmt()],
            },
        };
        this.addResizeListener();
        this.invoke();
    }

    addResizeListener() {
        window.addEventListener(
            'resize',
            () => {
                this.handleResize();
            },
            this.listenerOptions,
        );
    }

    handleResize() {
        this.setBackgroundImages();
        this.renderHeroAssets();
    }

    invoke() {
        this.setHappyHeroAssets().setBackgroundImages().renderHeroAssets();
    }

    setHappyHeroAssets() {
        this.heroAssets[HAPPY_KEY] = {
            mobile: this.hero?.dataset?.backgroundMobile,
            desktop: this.hero?.dataset?.backgroundDesktop,
        };
        return this;
    }

    setBackgroundImages() {
        this.heroBackgroundMobile = this.getMobileAsset();
        this.heroBackgroundDesktop = this.getDesktopAsset();
        return this;
    }

    getMobileAsset() {
        return this.heroAssets[this.getAssetKey()]?.mobile;
    }

    getDesktopAsset() {
        return this.heroAssets[this.getAssetKey()]?.desktop;
    }

    renderHeroAssets() {
        if (this.validExperimentBreakpoint() && this.isInVariant()) {
            this.addHeroBackgroundImage();
            this.updateVideos('hide');
        } else {
            this.hideHeroBackgroundImage();
            this.updateVideos('show');
        }
    }

    updateVideos(showHide) {
        const display = showHide === 'show' ? '' : 'none';
        VIDEO_SELECTORS.forEach((videoSelector) => {
            try {
                document.querySelector(videoSelector).style.display = display;
                document.querySelector(videoSelector + '-overlay').style.display = display;
            } catch (e) {
                // ignore
            }
        });
    }

    addHeroBackgroundImage() {
        this.__setBackgroundImage(this.getAsset());
    }

    hideHeroBackgroundImage() {
        this.hero.style.backgroundImage = '';
    }

    getAsset() {
        const assetKey = this.getAssetKey();
        const breakpointKey = this.getBreakpointKey();
        let asset = this.heroAssets[assetKey][breakpointKey];
        return asset;
    }

    getBreakpointKey() {
        return this.getCurrentBreakpointMax() <= this.getMobileAssetBreakpointMax()
            ? 'mobile'
            : 'desktop';
    }

    getAssetKey() {
        if (!this.validExperimentBreakpoint()) {
            return HAPPY_KEY;
        }

        if (this.isGeneralShowWall()) {
            return VARIANT_GENERAL;
        } else if (this.isSpecificShowWall()) {
            return VARIANT_SPECIFIC;
        } else {
            return HAPPY_KEY;
        }
    }

    validExperimentBreakpoint() {
        return this.getCurrentBreakpointMax() >= this.getMobileAssetBreakpointMax();
    }

    getCurrentBreakpointMax() {
        const cb = this.breakpoints.getCurrentBreakpointName();
        return this.breakpoints.getMax(cb);
    }

    getMobileAssetBreakpointMax() {
        return this.breakpoints.getMax('tabletPortrait');
    }

    isInVariant() {
        return this.isGeneralShowWall() || this.isSpecificShowWall();
    }

    isGeneralShowWall() {
        return this.abTest?.variant === VARIANT_GENERAL;
    }

    isSpecificShowWall() {
        return this.abTest?.variant === VARIANT_SPECIFIC;
    }

    getCbscidmt() {
        return new URLSearchParams(window.location.search).get('cbscidmt');
    }
}

// temp prototypes to be overwritten by setupPrototypesForIntlCenterAlignHeroAbTest below,
// called from AAUpsellHeroVideo.js
CenterContentInCampaignHeroesHandler.prototype.variants = {};
CenterContentInCampaignHeroesHandler.prototype.hero = null;
CenterContentInCampaignHeroesHandler.prototype.__setBackgroundImage = GENERIC_FUNCTION;
CenterContentInCampaignHeroesHandler.prototype.listenerOptions = GENERIC_FUNCTION;

export function setupPrototypesForCenterContentInCampaignAbTest() {
    return function () {
        CenterContentInCampaignHeroesHandler.prototype.hero = this.hero;
        CenterContentInCampaignHeroesHandler.prototype.__setBackgroundImage =
            this.__setBackgroundImage;
        CenterContentInCampaignHeroesHandler.prototype.listenerOptions = this.listenerOptions;
    };
}

/**
 *
 * @returns {boolean}
 */
export function centerContentInCampaignAbTest() {
    return typeof CBS?.AbTestsConfigs?.modules?.[AB_TEST_MODULE] !== 'undefined';
}

// END TEST PLOCTOPLUS-2313: Center Content In CAMPAIGN Hero Upsell Bucket
